import PropTypes from "prop-types";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../../../../Themes/defaultTheme";
import * as httpCall from "../../../../../Utils/apis/apis";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { convertToLabelValueFormat } from "../../../../../Utils/Helpers/common";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { InputAdornment } from "@material-ui/core";
import { envName } from "../../../../../Utils/config/config";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "222px",
    [theme.breakpoints.down("sm")]: {
      width: "222px",
    },
    //margin: theme.spacing(1),
    marginTop: 5,
  },
  selectLabel: {
    //marginTop: 7,
    color: "primary",
    marginTop: "-7px",
    // paddingLeft:"8px"
    // commenting this code.keeping this for future
    // refrence as it is a atoms which will be used in multiple places
  },
  selectLabel1: {
    //marginTop: 7,
    color: "primary",
    marginTop: "-1.8px",
    // paddingLeft:"8px"
    // commenting this code.keeping this for future
    // refrence as it is a atoms which will be used in multiple places
  },
  fullWidth: {
    width: "100%",
  },
  minWidth: {
    width: "180px",
  },
  helperIcon: {
    "& svg": {
      marginRight: "20px",
    },
    "& .helperIcon": {
      marginRight: "0px",
    },
  },
}));
// eslint-disable-next-line react/display-name
const A_AutoLookup = React.memo((props) => {
  const classes = useStyles();
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponseStart, setApiResponseStart] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionSets, setOptionSets] = useState([]);
  const defaultValue = props.defaultValue;
  const parsedValue =
    defaultValue && isJsonString(defaultValue)
      ? JSON.parse(defaultValue)
      : null;
  const {gremlinParareters }=props;
  let selectedValue = "";

  if (parsedValue && parsedValue[0] && parsedValue[0].title) {
    selectedValue = parsedValue[0].title;
  } else if (defaultValue && defaultValue.data) {
    selectedValue = defaultValue.data.title || defaultValue.data.value || "";
  }

  const [value, setValue] = useState(selectedValue);

  React.useEffect(() => {
    if (!apiResponseStart && props.spName && !isLoading) {
      getSearchResults();
    }
  }, []);

  const getSearchResults = async () => {
    setIsLoading(true);
    setApiResponseStart(true);
    let payload = { 
      ...gremlinParareters    }
    // let defaultFirst = [{ label: "", value: "" }];
    const response = await httpCall.httpPost(
      `/GenericGremlin/generic/${props.spName}`,payload
    );
    if (response.length > 0) {
      setIsLoading(false);
      let hostName = window.location.hostname;
      let optionss;
      if (
        hostName == envName.VELDCAPITAL ||
        hostName == envName.VELDQA ||
        hostName == envName.VELDQANEW ||
        hostName == envName.VELDDEV ||
        hostName == envName.VELDCAPITALMINERVA
      ) {
        if (props.destinationVertex === "currency") {
          response.forEach((item) => {
            item.label = `${item.value} (${item.label})`;
          });
          optionss = convertToLabelValueFormat("value", "label", response);
        } else {
          optionss = convertToLabelValueFormat("title", "title", response);
        }
      } else {
        optionss = convertToLabelValueFormat("title", "title", response);
      }

      let optionSets = optionss;
      setOptionSets(optionSets);
      let options = optionSets.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
      setOptions(options);
    }
  };
  const handleChange = (event) => {
    if (event.target.value !== "") {
      let selectedOption = optionSets.filter(function (option) {
        return option.value == event.target.value;
      })[0];
      setValue(selectedOption.value);
      props.onChange(
        {
          data: selectedOption ? selectedOption : "",
          edgeLabel: props.edgeLabel,
        },
        props.id,
        props.tabular ? true : false
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={props.error}
        key={props.id}
        id={props.id}
        variant={props.variant}
        className={`${
          props.className ? props.className : classes.formControl
        } ${props.fullWidth ? classes.fullWidth : ""} ${
          props.minWidth ? classes.minWidth : ""
        }`}
        required={props.required}
        style={props.style}
      >
        <InputLabel
          className={classes.selectLabel1}
          key={props.id}
          id={props.id}
        >
          {props.label}
        </InputLabel>
        <Select
          key={props.id}
          id={props.id}
          labelId={props.id}
          defaultValue={value}
          value={value}
          style={props.style}
          //variant={props.variant}
          endAdornment={
            <InputAdornment position="end">
              {isLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : null}
              {<A_FormHelper fieldHelperText={props.fieldHelperText} />}
            </InputAdornment>
          }
          disabled={props.disabled}
          required={props.required}
          autoWidth={props.autoWidth}
          displayEmpty={props.displayEmpty}
          inputProps={{
            readOnly: props.readOnly,
            style:
              props.inputProps && props.inputProps != ""
                ? props.inputProps
                : {},
          }}
          color={props.color}
          className={`${props.className} ${
            props.fieldHelperText ? classes.helperIcon : ""
          }`}
          onChange={handleChange}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {options}
        </Select>
        <FormHelperText key={"helptext" + props.id} id={"helptext" + props.id}>
          {props.helperText}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
});
A_AutoLookup.defaultProps = {
  id: "xxxx",
  //label: "Select",
  value: "",
  variant: "outlined",
  color: "primary",
  inputProps: "",
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  error: false,
  required: false,
  options: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  native: false,
  style: {},
  fieldHelperText: "",
  isCascading: false,
};
A_AutoLookup.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  className: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.array,
  native: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.any,
  groupedOptions: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  fullWidth: PropTypes.any,
  minWidth: PropTypes.any,
  optionSet: PropTypes.any,
  inputProps: PropTypes.any,
  fieldHelperText: PropTypes.string,
  isCascading: PropTypes.bool,
  spName: PropTypes.any,
  edgeLabel: PropTypes.any,
  tabular: PropTypes.any,
  gremlinParareters:PropTypes.any,
};
A_AutoLookup.displayName = A_AutoLookup;
export default A_AutoLookup;
